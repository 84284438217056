
import { Component, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import SupplierStaffList from "@/components/user/list/SupplierStaffList.vue";
@Component({
  components: { SupplierStaffList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // prop
  @Prop({ default: false })
  public is_same_user!: boolean;

  public supplier_id = 0;
  public created() {
    this.supplier_id = Number(this.$route.params.supplier_id);
  }
}
